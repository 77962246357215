
import Vue from "vue";

export default Vue.extend({
  name: "Alert",

  data: () => ({
    config: {
      incoming: {
        color: "success",
        icon: "mdi-alert-circle"
      },
      warning: {
        color: "warning",
        icon: "mdi-alert-circle"
      },
      success: {
        color: "success",
        icon: "mdi-checkbox-marked-circle"
      },
      error: {
        color: "error",
        icon: "mdi-alert"
      },
      info: {
        color: "primary",
        icon: "mdi-information"
      }
    }
  }),

  computed: {
    show(): boolean {
      return this.$store.getters["alerts/show"];
    },
    messages(): Array<MessageInterface> {
      return this.$store.getters["alert/messages"];
    }
  },

  methods: {
    hide(index: number): void {
      this.$store.dispatch("alert/hide", index);
    }
  }
});
